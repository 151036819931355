import React from "react";
import useSiteMetadata from "../../meta";

export default function Footer() {
  const { phone, addres, email } = useSiteMetadata();
  return (
    <div className="bg-slate-600 py-20 space-y-20">
      <div className="mx-auto container max-w-7xl">
        <div className="grid grid-cols-4 gap-8">
          <div className="space-y-4">
            <img
              src="https://kfpartners.ru/logo-footer.png"
              alt="Красильников, Фролов и партнеры"
            />
          </div>
          <div className="text-gray-400 col-span-2 space-y-3">
            <p className="text-gray-300 font-semibold text-lg">
              Группа компаний «Красильников, Фролов и партнеры»
            </p>
            <p>
              Адрес компании: <span className="text-gray-300">{addres}</span>
            </p>
            <p>
              Тел: <span className="text-gray-300">{phone}</span> E-mail:{" "}
              <span className="text-gray-300">{email}</span>
            </p>
          </div>
          <div className="text-gray-400 space-y-3">
            <p>ООО «КФП-КОНСАЛТИНГ»</p> <p>ОГРН: 1221600008497</p>
            <p>ИНН/КПП: 1655471436/165501001</p>
          </div>
        </div>
      </div>
    </div>
  );
}
