import React from "react";

import Header from "./components/header";
import Footer from "./components/footer";
import useSiteMetadata from "../meta";

const Contacts = () => {
  const { phone, addres, email } = useSiteMetadata();
  return (
    <>
      <Header />
      <main className="bg-gray-200 py-8 space-y-8">
        <div className="mx-auto  max-w-6xl container space-y-4">
          <div className="p-8 bg-white font-playfirDisplay italic text-2xl">
            Контакты
          </div>
          <div className="p-8 bg-white">
            <div className="flex flex-col space-y-8 py-8">
              <div className="font-semibold text-2xl">«КФП-консалтинг»</div>
              <div className="flex space-x-10">
                <div>
                  <p>ООО «КФП-КОНСАЛТИНГ»</p>
                  <p>ОГРН: 1221600008497</p>
                  <p>ИНН/КПП: 1655471436/165501001</p>
                </div>
                <div>
                  <p>Адрес: {addres}</p>
                  <p>Телефоны: {phone}</p>
                  <p>E-mail: {email}</p>
                </div>
              </div>
            </div>
            <div style={{ position: "relative", overflow: "hidden" }}>
              <a
                href="https://yandex.ru/maps/org/krasilnikov_frolov_i_partnery/120377155729/?utm_medium=mapframe&utm_source=maps"
                style={{
                  color: "#eee",
                  fontSize: "12px",
                  position: "absolute",
                  top: "0px",
                }}
              >
                Красильников, Фролов и партнеры
              </a>
              <a
                href="https://yandex.ru/maps/43/kazan/category/disinfection_disinfestation_pest_removal/184108211/?utm_medium=mapframe&utm_source=maps"
                style={{
                  color: "#eee",
                  fontSize: "12px",
                  position: "absolute",
                  top: "14px",
                }}
              >
                Консалтинговые услуги
              </a>
              <div>
                <iframe
                  src="https://yandex.ru/map-widget/v1/-/CCUyBYEaDC"
                  width="100%"
                  height="600"
                  title="d"
                  frameborder="1"
                  allowfullscreen="true"
                  style={{ position: "relative" }}
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Contacts;
